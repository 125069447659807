:root {
    --primary: #0283EF;
    --background: #ebeff3;
    --teritary: gray;

}

.column {
    display: flex;
    flex-direction: column;
}

.justifyCenter {
    justify-content: center;
}

.alignCenter {
    align-items: center;
}

.alignStart{
    align-items: flex-start;
}

.justifyBetween {
    justify-content: space-between;
}

.gap {
    gap: 10px;
}

/* Paddings */
.paddingAll {
    padding: 10px;
}


/* Margins */
.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

/* Text */
.headerText {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}


/* header */
.header {
    position: sticky;
    top: 0;
    width: 100%;
    left: 0;
    background-color: white;
}


/* container */


.input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    width: 100%;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.right {
    justify-content: right;
}

.left {
    justify-content: left;
}


/* Button */
.button {
    padding: 10px 20px;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
    background-color: var(--primary);
    color: #ffffff;
    cursor: pointer;
    border: none;
}

.button:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.button-cta {
    padding: 10px 20px;
    border-radius: 5px;
    outline: none;
    border: 2px solid #aab4bf;
    background: none;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button-cta:hover {
    background-color: #aab4bf;
    color: white;
}

/* Input */

.input-label {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #000000;
}

.text-input {
    padding: 10px;
    border: 1px solid var(--teritary);
    border-radius: 5px;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    transition: all 0.2s ease-in-out;
}


.checkbox-input {
    padding: 10px;
    border: 1px solid var(--teritary);
    border-radius: 5px;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    transition: all 0.2s ease-in-out;
    margin: 0;
    padding: 0;
}

.select-input {
    padding: 10px;
    border: 1px solid var(--teritary);
    border-radius: 5px;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    transition: all 0.2s ease-in-out;
    appearance: none;
}

.textarea-input {
    padding: 10px;
    border: 1px solid var(--teritary);
    border-radius: 5px;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    transition: all 0.2s ease-in-out;
    resize: none;
    min-height: 100px;
}

.text-input:hover,
.textarea-input:hover {
    border: 2px solid var(--primary);
}

.backgroundLess {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}

/*  */

.link {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    text-decoration: none;
}


/* navbar */
.navbar-main {
    display: flex;
    flex-direction: column;
    /* flex: 0 0 200px; */
    height: 100vh;
    background-color: #12344d;
    position: static;
} 


.navbar-container{
    /* overflow-y: auto; */
    height: 100%;
}

.navbarItem {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.navbarItem:hover {
    background-color: #2972ae;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.navbar-menu {
    position: absolute;
    left: 300px;
    top: 0;
    z-index: 101;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.1s ease-in-out;
    width: 200px;
}

.navbarItemChild{
    margin-top: 3px;
    padding: 10px;
    border-radius: 10px;
} 


.navbarItemChild:hover{
    /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); */
}

.component {
    background-color: #f5f7f9;
    border-bottom: 1px solid #cfd7df;
    padding: 20px;
}

.component-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    padding: 0px;
    margin: 0px;
}

.card {
    background-color: white;
    border-bottom: 1px solid #cfd7df;
    padding: 10px 20px;
    width: 100%;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.card-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
}

/* add max width:1000 */
@media only screen and (max-width: 1000px) {

    /* .navbar{
        padding: 20px;
    } */
    .header{
        padding: 20px;
    }
}

/* modal */
.modal-container{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;       
}
.modal-content{
    width: 500px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.modal-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}
.modal-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.blockbuilder-branding{
    display: none;
}

.header-link{
    text-decoration: none;
    color: #000000;
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    gap: 5px;
}
